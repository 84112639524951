input[type="file"] {
    display: none;
}
.custom-button {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #ffffff; /* Green */

}


.axis text {
    fill: none;
  }
